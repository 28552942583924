<template>
	<van-space v-if="this.isEmpty(this.form)" direction="vertical" fill style="height: calc(100vh - 124px)">
		<van-loading size="24px" vertical style="display: flex; margin: auto;">Loading...</van-loading>
	</van-space>
	<van-space v-else direction="vertical" fill>
		<div class="van-block">
			<h2 class="van-block_title">{{ this.translate('App Settings', this.lang) }}</h2>
			<van-cell-group inset class="van-block_card">

				<van-cell center :title="this.translate('Mute sound', this.lang)">
					<template #right-icon>
						<van-switch v-model="this.form.mute_ui_sound"
									@change="(value) => this.setAndValidate('mute_ui_sound', value, 'switch')" />
					</template>
				</van-cell>

				<van-field name="sound_level" :label="this.translate('Sound level', this.lang)">
					<template #input>
						<van-slider v-model="this.form.sound_level"
									:min="0"
									:step="0.1"
									:max="1"
									:disabled="this.form.mute_ui_sound"
									@change="(value) => this.setAndValidate('sound_level', value, 'slider')" />
					</template>
				</van-field>

				<van-field v-model="this.form.color_scheme"
						   is-link
						   readonly
						   name="color_scheme"
						   :label="this.translate('Color Scheme', this.lang)"
						   label-align="left"
						   :placeholder="this.translate('Select item', this.lang)"
						   @click="this.shown.pickers.color_scheme = true" />

			</van-cell-group>

			<!-- Popups -->
			<van-popup v-model:show="this.shown.pickers.color_scheme"
					   position="bottom">
				<van-picker :title="this.translate('Color Scheme', this.lang)"
							:columns="this.collection.color_schemes"
							@scrollInto="(event) => this.emitter.emit('playSound', 'tick')"
							@confirm="(value) => this.setAndValidate('color_scheme', value, 'picker')"
							@cancel="this.shown.pickers.color_scheme = false" />
			</van-popup>
		</div>
		<div class="van-block">
			<h2 class="van-block_title">{{ this.translate('Privacy', this.lang) }}</h2>
			<van-cell-group inset class="van-block_card">

				<van-cell center :title="this.translate('Screen Notify', this.lang)">
					<template #right-icon>
						<van-switch v-model="this.form.screen_notify"
									@change="(value) => this.setAndValidate('screen_notify', value, 'switch')" />
					</template>
				</van-cell>

				<van-cell center :title="this.translate('PIN-code', this.lang)">
					<template #right-icon>
						<van-switch v-model="this.form.use_pin_code"
									@click="this.shown.dialogs.use_pin_code = true" />
					</template>
				</van-cell>

				<van-cell v-if="this.isWebAuthSupported" center :title="this.translate('Face ID', this.lang)">
					<template #right-icon>
						<van-switch v-model="this.form.use_device_auth"
									@change="(value) => this.setAndValidate('use_device_auth', value, 'switch')" />
					</template>
				</van-cell>

			</van-cell-group>

			<!-- Popups -->
			<van-popup v-model:show="this.shown.pickers.color_scheme"
					   position="bottom">
				<van-picker :title="this.translate('Color Scheme', this.lang)"
							:columns="this.collection.color_schemes"
							@scrollInto="(event) => this.emitter.emit('playSound', 'tick')"
							@confirm="(value) => this.setAndValidate('color_scheme', value, 'picker')"
							@cancel="this.shown.pickers.color_scheme = false" />
			</van-popup>

			<!-- Dialogs -->
			<van-dialog v-model:show="this.shown.dialogs.use_pin_code"
						:title="this.translate('New PIN', this.lang)"
						:confirmButtonDisabled="this.isEmpty(this.form.pin_code) || this.form.pin_code.length != 6"
						show-cancel-button
						@cancel="this.form.use_pin_code = false"
						@confirm="this.setAndValidate('use_pin_code', value, 'switch')" >

				<van-password-input :value="this.form.pin_code"
									:focused="this.shown.pin_code_keyboard"
									style="padding: 25px 0"
									@focus="this.shown.pin_code_keyboard = true" />

			</van-dialog>

			<van-number-keyboard v-model="this.form.pin_code"
								 :show="this.shown.pin_code_keyboard"
								 z-index="3001"
								 @blur="this.shown.pin_code_keyboard = false" />
		</div>
	</van-space>
</template>

<script>
import CommonService from "@/services/CommonService";
import {useCurrentLang} from "vant";
import collections from "@/data/collections";
import {ref} from "vue";

export default {
	name: "EditSettings",
	components: {

	},
	data() {
		return {
			lang: 'en-US',
			translations: this.$root.intl.translations,
			form: {},
			collection: {
				color_schemes: [],
			},
			shown: {
				pin_code_keyboard: false,
				pickers: {

				},
				dialogs: {
					use_pin_code: false,
				}
			},
			is_form_changed: false,
			errors: [],
		};
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
			return CommonService.inArray(needle, haystack);
		},
		toRoute(path, refresh) {

			if (refresh)
				window.location.href = path
			else
				this.$router.push(path);

		},
		translate(string, lang, append, prepend) {

			if (typeof lang !== "undefined") {
				if (!this.isEmpty(this.translations[lang])) {
					if (!this.isEmpty(this.translations[lang][string])) {
						string = this.translations[lang][string];
					}
				}
			}

			if (!this.isEmpty(prepend))
				string = prepend + string;

			if (!this.isEmpty(append))
				string = string + append;

			return string;
		},
		inputValidate(value, input) {
			let message = null;
			return message;
		},
		setAndValidate(name, value, type) {

			let errors = [];

			if (!this.isEmpty(value)) {

				if (type == 'picker') {
					value = value.selectedValues.reverse().join(', ');

					if (!this.isEmpty(this.shown.pickers[name]))
						this.shown.pickers[name] = false

				}

				if (typeof (this.form[name]) == "object" && typeof (value) == "string") {
					this.form[name].push(value);
				} else {
					this.form[name] = value;
				}
			}

			console.debug('setAndValidate()', { name: name, value: value, type: type, lang: this.lang, errors: errors });

			if (!this.isEmpty(errors)) {
				this.errors = errors;
				return false;
			}

			if (type == 'switch')
				this.emitter.emit('playSound', 'switch');
			else if (type == 'picker')
				this.emitter.emit('playSound', 'tick');

			this.is_form_changed = true;
			return true;
		},
		getList(name) {
			let _this = this;
			return new Promise(function(resolve, reject) {

				let list = [];
				if (!_this.isEmpty(collections[name])) {
					list = collections[name];
					resolve(list);
				} else {
					reject(false);
				}
			});
		},
		getUserSettings() {
			return new Promise((resolve) => setTimeout(() => resolve(this.$root.settings), 1000));
		},
		saveSettings(fields) {
			if (this.isEmpty(this.errors)) {
				CommonService.debounce(() => {
					console.info('saveSettings', {
						fields: fields
					});
					this.$store.commit('setSettings', this.form);
					this.emitter.emit('setSettings', this.form);
					this.emitter.emit('showNotify', {type: 'success', message: "Saved!"})
				}, 3000);
			}
		}
	},
	mounted() {

		this.lang = useCurrentLang();

		this.getList('color_schemes').then((list) => this.collection.color_schemes = list);

		this.getUserSettings().then((data) => this.form = {...this.$root.settings, ...data});
		this.is_form_changed = false;
	},
	computed: {
		isWebAuthSupported() {
			return !(typeof navigator.credentials !== 'object' || typeof navigator.credentials.create !== 'function' || typeof navigator.credentials.get !== 'function')
		}
	},
	watch: {
		'shown.dialogs.use_pin_code': function(value, oldValue) {
			if (value) {
				if (this.form.pin_code.length > 0) {
					let pin_length = this.form.pin_code.length;
					for (let i = 0; i < pin_length; i++) {
						this.form.pin_code = this.form.pin_code.substr((this.form.pin_code.length - 1), 1);
					}
				}
			}
		},
		'form.pin_code': function(value, oldValue) {
			if (value) {
				if (this.form.pin_code.length > 6) {
					this.form.pin_code = this.form.pin_code.substr(0, 6);
				}
			}
		},
		'form.use_pin_code': function(value, oldValue) {

			if (value && !oldValue)
				this.shown.dialogs.use_pin_code = true;
			else
				this.shown.dialogs.use_pin_code = false;

			if (value)
				this.form.use_device_auth = false;

		},
		'form.use_device_auth': function(value, oldValue) {
			if (value)
				this.form.use_pin_code = false;

		},
		form: {
			handler: function(fields, oldFields) {
				if (this.is_form_changed) {
					this.saveSettings(fields);
				}
			},
			deep: true
		},
	},
}
</script>

<style scoped>

</style>